"use client";
import PrimaryButton from "../Forms/Button/PrimaryBtn";
import { IoPricetagsSharp } from "react-icons/io5";

type Props = {
  network: string;
  address: string;
};

export default function BtnShop({ network, address }: Props) {
  return (
    <PrimaryButton
      type="submit"
      style="w-full"
      onClick={() => window.open(`/collections/${network}/${address}`, "_self")}
    >
      <IoPricetagsSharp className="mr-2 text-[20px]" />
      Shop
    </PrimaryButton>
  );
}
